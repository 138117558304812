/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./section1.css";
import { Box } from "@mui/material";

const Section1 = ({ handleModalOpen }) => {
  const [amount, setAmount] = useState(0);
  const [month, setMonth] = useState(0);
  const [interestRate, setInterestRate] = useState(12);
  const [interest, setInterest] = useState("");
  const [emi, setEmi] = useState("");
  useEffect(() => {
    if (amount > 0 && month > 0) {
      let total = calculateTotalAmount(amount, interestRate, month);
      console.log(total, "totalAmount, amount");
      setInterest(total);
      let emiAmount = parseInt(amount) + total;
      console.log(typeof amount, "amount type");
      console.log(typeof total, "amount type");
      console.log(emiAmount, "emi");
      let emi = parseInt(emiAmount / month);
      setEmi(emi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, month, interestRate]);

  function calculateTotalAmount(principal, rate, time) {
    debugger;
    // Ensure rate is in percentage form
    // rate = rate;
    let tempTime = time / 12;

    // Calculate total amount
    var totalAmount = (principal * rate * tempTime) / 100;

    return totalAmount;
  }

  return (
    <div>
      <section className="about-one" id="about-id">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-one__content">
                <div className="block-title text-left">
                  <p>About us</p>
                  <h2>Loans At Your Best</h2>
                </div>
                <p className="about-one__text">
                  Your Journey From “Less To More Than”
                </p>
                <p>
                  Matsya Fincap, a noted financial institution honoured for its
                  hassle free and trusted lending approach has been continuously
                  serving you the finance at your need and customization. With
                  your trust and immense support since 2015, we have been able
                  to achieve a milestone in the financial sphere with the vision
                  of providing Loans At Your Best. Being your reliable fintech
                  partner, we aim at empowering individuals and businesses by
                  providing them tailored financial solutions with minimum
                  interest rates and always acceptable customizations according
                  to your need.
                </p>
                <Box
                  sx={{
                    boxShadow: "0 10px 60px 0 rgba(0,0,0,.1)",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    mt: "2rem",
                    p: {sm:"2rem",xs:"1rem"},
                    display:"inline-block",
                    "& p":{
                      mb:"1rem"
                    }
                  }}
                >
                  <p>
                    Interest rates - 18% to 40% Per Annum (subject to risk)
                  </p>
                  <p>
                    Service charges - Nil
                  </p>
                  <p>
                    Penal charges - Nil
                  </p>
                </Box>
              </div>
            </div>
            <div className="col-lg-6">
              <form
                action="#"
                id="loan-calculator"
                data-interest-rate="15"
                className="about-one__form wow fadeInRight"
                data-wow-duration="1500ms"
                style={{ visibility: "visible", animationDuration: "1500ms" }}
              >
                <h3>How Much You Need</h3>
                <div className="about-one__form-content">
                  <div className="input-box__top">
                    <span>₹ 1000</span>
                    <span>₹ 200000</span>
                  </div>
                  <div className="input-box">
                    <div className="range-slider-count" id="range-slider-count">
                      <input
                        type="range"
                        className="w-100"
                        min="0"
                        max="200000"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                    ₹ <span>{amount}</span>
                    <input
                      type="hidden"
                      id="min-value-rangeslider-count"
                      value=""
                    />
                    <input
                      type="hidden"
                      id="max-value-rangeslider-count"
                      value=""
                    />
                  </div>
                  <div className="input-box__top">
                    <span>1 Month</span>
                    <span>12 Months</span>
                  </div>
                  <div className="input-box">
                    <div className="range-slider-month" id="range-slider-month">
                      <input
                        type="range"
                        className="w-100"
                        min="1"
                        max="12"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      />
                    </div>
                    <span>{month} month</span>
                    <input
                      type="hidden"
                      id="min-value-rangeslider-month"
                      value=""
                    />
                    <input
                      type="hidden"
                      id="max-value-rangeslider-month"
                      value=""
                    />
                  </div>

                  {/* check slider */}

                  <div className="input-box__top">
                    <span>12%</span>
                    <span>50%</span>
                  </div>
                  <div className="input-box">
                    <div className="range-slider-month" id="range-slider-month">
                      <input
                        type="range"
                        className="w-100"
                        min="12"
                        max="50"
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                      />
                    </div>
                    <span>{interestRate}% rate</span>
                    <input
                      type="hidden"
                      id="min-value-rangeslider-month"
                      value=""
                    />
                    <input
                      type="hidden"
                      id="max-value-rangeslider-month"
                      value=""
                    />
                  </div>
                  <p>
                    <span>Rate of interest</span>
                    <b>
                      <i id="loan-monthly-pay">{interestRate}%</i>
                    </b>
                  </p>
                  <p>
                    <span>Terms of Use</span>
                    <b>
                      <i id="loan-month">{month}</i> Months
                    </b>
                  </p>
                  <p>
                    <span>Pay Monthly</span>
                    <b>
                      ₹<i id="loan-monthly-pay">{emi == 0 ? 0 : emi}</i>
                    </b>
                  </p>
                  <p>
                    <span>Total Interest</span>
                    <b>
                      ₹
                      <i id="loan-monthly-pay">
                        {interest == 0 ? 0 : interest}
                      </i>
                    </b>
                  </p>

                  <p>
                    <span>Total Pay Back</span>
                    <b>
                      ₹<i id="loan-total">{parseInt(amount) + interest}</i>
                    </b>
                  </p>
                  <button
                    type="submit"
                    className="thm-btn"
                    onClick={handleModalOpen}
                  >
                    Apply For Loan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section1;
